//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },
  props: {
    selUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      indexData: {
        type: "",
        name: "成员详情",
        xModalName: "userTeamInfo",
      },
    };
  },
  methods: {
    imgChange,
  },
};
